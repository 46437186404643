import React from 'react';
import Scheduler from './components/Scheduler';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container>
        <Scheduler />
      </Container>
    </ThemeProvider>
  );
};

export default App;

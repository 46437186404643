import React, { useRef, useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DownloadIcon from '@mui/icons-material/Download';

const getBorderStyle = (hasBorderRight) => (hasBorderRight ? '1px solid #ccc' : 'none');

const StyledCell = styled(TableCell)(({ theme, hasborderright }) => ({
  height: '50px',
  textAlign: 'center',
  position: 'relative',
  padding: '0',
  verticalAlign: 'middle',
  borderBottom: '1px solid #eee',
  backgroundColor: 'inherit',
  borderRight: getBorderStyle(hasborderright),
  fontSize: '8px'
}));

const HeaderCell = styled(TableCell)(({ theme }) => ({
  textAlign: 'center',
  borderBottom: '1px solid #ccc',
  borderRight: '1px solid #ccc',
  backgroundColor: theme.palette.background.paper,
  textTransform: 'uppercase'
}));

const TimeCell = styled(TableCell)(({ theme }) => ({
  textAlign: 'center',
  borderBottom: '1px solid #ccc',
  borderRight: '1px solid #ccc',
  backgroundColor: theme.palette.background.paper,
  position: 'sticky',
  left: 0,
  zIndex: 1000
}));

const SmallButton = styled(Button)(({ theme, selected, bgcolor }) => ({
  whiteSpace: 'nowrap',
  margin: '2px',
  fontSize: '10px', // Updated for medium size
  padding: '4px 8px', // Updated for medium size
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: selected ? '#3f51b5' : 'white',
  color: selected ? 'white' : 'black',
  height: 'auto', // Adjust height for text to fit
  minWidth: '60px', // Ensure the button has a minimum width to fit the text
  border: selected ? 'none' : '1px solid #ccc',
  borderRadius: '4px',
  position: 'relative',
  overflow: 'hidden', // Ensure text does not overflow
  textOverflow: 'ellipsis',
  '&::after': {
    content: '""',
    width: '100%',
    height: '2px',
    backgroundColor: bgcolor,
    position: 'absolute',
    bottom: '0',
    left: '0'
  }
}));

const StyledButton = styled(Button)(({ theme }) => ({
  whiteSpace: 'nowrap',
  margin: '2px',
  fontSize: '10px',
  padding: '4px 8px', // Updated for medium size
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'white',
  color: 'black',
  height: '30px',
  minWidth: '60px', // Ensure the button has a minimum width to fit the text
  border: '1px solid #ccc',
  borderRadius: '4px',
  position: 'relative'
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  '& h1, & h6': {
    margin: '0',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    fontSize: '1.25rem',
    lineHeight: 1.6,
    letterSpacing: '0.0075em',
    textAlign: 'center',
    marginBottom: '0.35em'
  },
  '& .filter-container': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(4),
    gap: theme.spacing(1),
    fontSize: '12px',
    maxWidth: '800px',
    margin: '0 auto'
  }
}));

const TableWrapper = styled('div')({
  overflowX: 'auto',
  maxHeight: 'none',
  position: 'relative',
  marginBottom: '12px'
});

const processActivityData = (data) => {
  return data.reduce((acc, app) => {
    const div = document.createElement('div');
    div.innerHTML = app.attivita;
    const text = div.querySelector('strong').innerText || '';
    const hr = div.querySelector('hr');
    const color = hr ? hr.style.backgroundColor : '#000';

    if (!acc[text]) {
      acc[text] = { color, id: text.replace(/\s+/g, '-').toLowerCase() };
    }

    return acc;
  }, {});
};

const Scheduler = () => {
  const tableRef = useRef();
  const [selectedActivities, setSelectedActivities] = useState({});
  const [availableActivities, setAvailableActivities] = useState({});
  const [data, setData] = useState({ orari: [], giorni: [], appuntamenti: [] });
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState('dal 9/9/2024 all’8/6/2025');

  const fetchData = async () => {
    const rootElement = document.getElementById('root');
    const pageSlug = rootElement ? rootElement.getAttribute('data-slug') : '';
    const endpoint = `https://www.coopernuoto.it/wp-json/custom/v1/tabella-orario-json/${pageSlug}`;

    if (pageSlug === 'soliera-piscina' || pageSlug === 'soliera-palestra') {
      setDateRange('dal 26/8/2024 all’8/6/2025');
    }

    try {
      const response = await fetch(endpoint);
      const result = await response.json();
      console.log('Fetched data:', result);

      if (Array.isArray(result)) {
        processAndSetData(result);
      } else {
        console.error('I dati recuperati non sono un array:', result);
      }

      setLoading(false);
    } catch (error) {
      console.error('Errore nel caricamento dei dati:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const processAndSetData = (data) => {
    const orariSet = new Set();
    const giorniSet = new Set();
    const activities = processActivityData(data);

    data.forEach((app) => {
      orariSet.add(app.orario_attivita);
      giorniSet.add(app.day);
    });

    setData({
      orari: Array.from(orariSet).sort(),
      giorni: Array.from(giorniSet),
      appuntamenti: data
    });

    setAvailableActivities(activities);

    const initialSelectedActivities = Object.keys(activities).reduce((acc, activity) => {
      acc[activities[activity].id] = false;
      return acc;
    }, {});

    setSelectedActivities(initialSelectedActivities);

    // Save buttons to localStorage
    localStorage.setItem('availableActivities', JSON.stringify(activities));
  };

  const getAppuntamenti = (giorno, orario) => {
    return data.appuntamenti.filter((app) => app.day === giorno && app.orario_attivita === orario);
  };

  const toggleActivitySelection = (activityId) => {
    setSelectedActivities((prevSelectedActivities) => ({
      ...prevSelectedActivities,
      [activityId]: !prevSelectedActivities[activityId]
    }));
  };

  const resetFilter = () => {
    const resetActivities = Object.keys(selectedActivities).reduce((acc, activity) => {
      acc[activity] = false;
      return acc;
    }, {});
    setSelectedActivities(resetFilter);
  };

  const exportPDF = () => {
    const pageTitle = document.title;

    const pdf = new jsPDF('p', 'mm', 'a4');

    const tableContent = [];

    data.giorni.forEach((giorno) => {
      data.orari.forEach((orario) => {
        const appuntamenti = getAppuntamenti(giorno, orario);
        const filteredAppuntamenti = filteredActivities.length > 0
          ? appuntamenti.filter((app) => {
              const div = document.createElement('div');
              div.innerHTML = app.attivita;
              const text = div.querySelector('strong').innerText || '';
              const activityId = availableActivities[text] ? availableActivities[text].id : '';
              return filteredActivities.includes(activityId);
            })
          : appuntamenti;

        if (filteredAppuntamenti.length > 0) {
          filteredAppuntamenti.forEach((app) => {
            const div = document.createElement('div');
            div.innerHTML = app.attivita;
            const text = div.querySelector('strong').innerText || '';
            tableContent.push([giorno, orario, text]);
          });
        }
      });
    });

    pdf.text('Attività settimanali', pdf.internal.pageSize.getWidth() / 2, 10, { align: 'center' });
    pdf.text(dateRange, pdf.internal.pageSize.getWidth() / 2, 20, { align: 'center' });

    pdf.autoTable({
      head: [['Giorno', 'Orario', 'Attività']],
      body: tableContent,
      startY: 30,
      styles: { halign: 'center' },
    });

    const creationDate = new Date().toLocaleDateString();
    pdf.setFontSize(10);
    pdf.text(
      `Lo staff di ogni impianto può variare giorni e orari delle attività elencate - Data di creazione del documento: ${creationDate}`,
      pdf.internal.pageSize.getWidth() - 10,
      pdf.internal.pageSize.getHeight() - 10,
      { align: 'right' }
    );

    pdf.save(`${pageTitle} Orari attività.pdf`);
  };

  const filteredActivities = Object.keys(selectedActivities).filter((activityId) => selectedActivities[activityId]);

  useEffect(() => {
    const activities = localStorage.getItem('availableActivities');
    if (activities) {
      setAvailableActivities(JSON.parse(activities));
    }
  }, []);

  if (loading) {
    return (
      <StyledContainer>
        <Skeleton variant="text" height={40} />
        <Skeleton variant="rectangular" height={400} />
      </StyledContainer>
    );
  }

  return (
    <StyledContainer>
      <div>
        <Typography variant="h1" gutterBottom>
          Attività settimanali
        </Typography>
        <Typography variant="h6" gutterBottom>
          {dateRange}
        </Typography>
        <div className="filter-container">
          <Grid container spacing={1} justifyContent="center">
            {Object.keys(availableActivities)
              .sort()
              .map((activity) => (
                <Grid item key={availableActivities[activity].id}>
                  <SmallButton
                    onClick={() => toggleActivitySelection(availableActivities[activity].id)}
                    selected={selectedActivities[availableActivities[activity].id]}
                    bgcolor={availableActivities[activity].color}
                  >
                    {activity}
                  </SmallButton>
                </Grid>
              ))}
          </Grid>
        </div>
        <div className="filter-container">
          <Grid container spacing={1} justifyContent="center" marginTop={2}>
            <Grid item>
              <StyledButton
                variant="contained"
                color="secondary"
                onClick={resetFilter}
                disabled={filteredActivities.length === 0}
                startIcon={<RestartAltIcon />}
              >
                Reset
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                onClick={exportPDF}
                variant="contained"
                color="primary"
                disabled={filteredActivities.length === 0}
                startIcon={<DownloadIcon />}
              >
                Esporta in PDF
              </StyledButton>
            </Grid>
          </Grid>
        </div>
      </div>
      <TableWrapper>
        <TableContainer component={Paper} ref={tableRef}>
          <Table>
            <TableHead className="MuiTableHead-root css-15wwp11-MuiTableHead-root">
              <TableRow>
                <HeaderCell></HeaderCell>
                {data.giorni.map((giorno) => (
                  <HeaderCell key={giorno}>{giorno}</HeaderCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.orari.map((orario) => {
                const hasSelectedActivities = data.giorni.some((giorno) => {
                  const appuntamenti = getAppuntamenti(giorno, orario);
                  const filteredAppuntamenti =
                    filteredActivities.length > 0
                      ? appuntamenti.filter((app) => {
                          const div = document.createElement('div');
                          div.innerHTML = app.attivita;
                          const text = div.querySelector('strong').innerText || '';
                          const activityId = availableActivities[text] ? availableActivities[text].id : '';
                          return filteredActivities.includes(activityId);
                        })
                      : appuntamenti;
                  return filteredAppuntamenti.length > 0;
                });
                if (!hasSelectedActivities && filteredActivities.length > 0) return null;
                return (
                  <TableRow key={orario}>
                    <TimeCell>{orario}</TimeCell>
                    {data.giorni.map((giorno, colIndex) => {
                      const appuntamenti = getAppuntamenti(giorno, orario);
                      const filteredAppuntamenti =
                        filteredActivities.length > 0
                          ? appuntamenti.filter((app) => {
                              const div = document.createElement('div');
                              div.innerHTML = app.attivita;
                              const text = div.querySelector('strong').innerText || '';
                              const activityId = availableActivities[text] ? availableActivities[text].id : '';
                              return filteredActivities.includes(activityId);
                            })
                          : appuntamenti;
                      return (
                        <StyledCell key={giorno} hasborderright={colIndex !== data.giorni.length - 1 ? 'true' : 'false'}>
                          {filteredAppuntamenti.length > 1 ? (
                            <div style={{ height: '100%', textAlign: 'center' }}>
                              {filteredAppuntamenti.map((app, index) => {
                                const div = document.createElement('div');
                                div.innerHTML = app.attivita;
                                const text = div.querySelector('strong').innerText || '';
                                const hr = div.querySelector('hr');
                                const color = hr ? hr.style.backgroundColor : '#000';
                                return (
                                  <SmallButton key={index} bgcolor={color}>
                                    {text}
                                  </SmallButton>
                                );
                              })}
                            </div>
                          ) : (
                            <>
                              {filteredAppuntamenti[0] &&
                                (() => {
                                  const div = document.createElement('div');
                                  div.innerHTML = filteredAppuntamenti[0].attivita;
                                  const text = div.querySelector('strong').innerText || '';
                                  const hr = div.querySelector('hr');
                                  const color = hr ? hr.style.backgroundColor : '#000';
                                  return (
                                    <SmallButton bgcolor={color}>
                                      {text}
                                    </SmallButton>
                                  );
                                })()}
                            </>
                          )}
                        </StyledCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </TableWrapper>
    </StyledContainer>
  );
};

export default Scheduler;